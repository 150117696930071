import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: '/login',
            component: () => import('@/pages/Login.vue'),
        },
        {
            path: '/main',
            component: () => import('@/layout/Main.vue'),
            redirect: '/main/user/list',
            children: [
                {
                    path: '/main/user/list',
                    component: () => import('@/pages/backend/user/List.vue'),
                },
                {
                    path: '/main/user/add',
                    component: () => import('@/pages/backend/user/Add.vue'),
                },
                {
                    path: '/main/user/modify',
                    component: () => import('@/pages/backend/user/Modify.vue'),
                },
                {
                    path: '/main/dictionary/list',
                    component: () => import('@/pages/backend/dictionary/List.vue'),
                },
                {
                    path: '/main/dictionary/add',
                    component: () => import('@/pages/backend/dictionary/Add.vue'),
                },
                {
                    path: '/main/dictionary/modify',
                    component: () => import('@/pages/backend/dictionary/Modify.vue'),
                },

                {
                    path: '/main/memoryrecord/list',
                    component: () => import('@/pages/backend/memoryrecord/List.vue'),
                },

                {
                    path: '/main/word/list',
                    component: () => import('@/pages/backend/word/List.vue'),
                },

                {
                    path: '/main/user/updatepwd',
                    component: () => import('@/pages/backend/UpdatePwd.vue'),
                }
            ]
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.path === '/login' || to.path === '/register' || to.path === '/') {
        next();
    } else {
        let token = localStorage.getItem('token');
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    }
})

export default router
