<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
}
img{
  vertical-align: middle;
}
</style>
